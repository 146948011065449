<template>
  <div class="evaluate wrapper">
    <van-form
      @submit="onSubmit"
      class="evaluate-form"
      validate-trigger="onSubmit"
    >
      <h3 class="evaluate-title">年级班级</h3>
      <van-field
      :border="false"
        class="customize-select customize-margin-bottom"
        v-model="fieldValue"
        readonly
        right-icon="arrow-down"
        name="grade-class"
        placeholder="请选择班级"
        @click="show = true"
        :rules="[{ required: true, message: '请选择班级' }]"
      />
      <van-popup class="fiexd-max-width" v-model="show" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择班级"
          :options="options"
          active-color="#ffa65f"
          @close="show = false"
          @finish="onFinish"
        />
      </van-popup>

      <h3 class="evaluate-title">评价内容</h3>
      <van-field
      :border="false"
      class="cancel-padding"
        name="evaluationOptions"
        :rules="[{ required: true, message: '请选择评价内容' }]"
      >
        <template #input>
          <van-radio-group
            direction="evaluation-options"
            v-model="evaluationOptions"
            class="customize-margin-bottom"
          >
            <van-radio icon-size="16px" name="入校行为"
              >入校行为
              <template #icon="props">
                <span
                  class="customize-radio-icon"
                  :class="props.checked ? 'checked' : ''"
                ></span>
              </template>
            </van-radio>
            <van-radio icon-size="16px" name="纪律卫生">
              纪律卫生
              <template #icon="props">
                <span
                  class="customize-radio-icon"
                  :class="props.checked ? 'checked' : ''"
                ></span>
              </template>
            </van-radio>
            <van-radio icon-size="16px" name="课间行为">
              课间行为
              <template #icon="props">
                <span
                  class="customize-radio-icon"
                  :class="props.checked ? 'checked' : ''"
                ></span>
              </template>
            </van-radio>
            <van-radio icon-size="16px" name="大课间">
              大课间
              <template #icon="props">
                <span
                  class="customize-radio-icon"
                  :class="props.checked ? 'checked' : ''"
                ></span>
              </template>
            </van-radio>
            <van-radio icon-size="16px" name="放学路队"
              >放学路队
              <template #icon="props">
                <span
                  class="customize-radio-icon"
                  :class="props.checked ? 'checked' : ''"
                ></span>
              </template>
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <h3 class="evaluate-title">评语</h3>
      <van-field
      :border="false"
        v-model="comments"
        type="textarea"
        name="comments"
        :rules="[{ required: true, message: '请添加评语' }]"
        placeholder="请添加评语"
        class="customize-comments customize-margin-bottom"
      />

      <h3 class="evaluate-title">照片</h3>

      <van-field name="photo" class="cancel-padding" :border="false">
        <template #input>
          <van-uploader
            v-model="photoList"
            multiple
            :after-read="afterRead"
            class="customize-margin-bottom"
            :preview-options="{'closeable':true}"
            max-count="5"
          />
        </template>
      </van-field>

      <van-button
        round
        block
        :loading="submitDisabled"
        loading-text="提交"
        color="linear-gradient(to right, #ffa65f, #ffc663)"
        :disabled="submitDisabled"
        type="info"
        native-type="submit"
        >提交</van-button
      >
    </van-form>
  </div>
</template>

<style scoped>
.evaluate-title {
  font-size: 18px;
  margin: 0 0 15px;
}

.customize-radio-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #c8c9cc;
  background: #ffffff;
}
.customize-radio-icon::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: transparent;
}
.customize-radio-icon.checked {
  border-color: #ffa65f;
}
.customize-radio-icon.checked::before {
  background: #ffa65f;
}

.evaluate-form .van-radio {
  margin: 0 0 15px;
  align-items: baseline;
}

.customize-select {
  border: 1px solid #c8c9cc;
  background: rgba(255,255,255,0.5) !important;
  font-size: 16px;
  border-radius: 5px;
  padding: 10px;
}
.customize-margin-bottom {
  margin: 0 0 30px;
  overflow: unset;
}
.customize-comments {
  border: 1px solid #c8c9cc;
  background: rgba(255,255,255,0.5) !important;
  font-size: 16px;
  border-radius: 5px;
  padding: 10px;
}

.cancel-padding{padding: 0;}

</style>

<style>
.van-field__error-message {
  position: absolute;
  bottom: -35px;
}
.van-uploader__upload{
  border-radius: 5px;
  border: 1px solid #ddd;
}

.evaluate .van-cell{background: transparent;}
</style>
<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  data() {
    return {
      show: false,
      fieldValue: "",
      cascaderValue: "",
      comments: "",
      photo: "",
      options: [
        {
          text: "一年级",
          value: "1",
          children: [
            { text: "一班", value: "101" },
            { text: "二班", value: "102" },
            { text: "三班", value: "103" },
            { text: "四班", value: "104" },
            { text: "五班", value: "105" },
            { text: "六班", value: "106" },
            { text: "七班", value: "107" },
            { text: "八班", value: "108" },
          ],
        },
        {
          text: "二年级",
          value: "2",
          children: [
            { text: "一班", value: "201" },
            { text: "二班", value: "202" },
            { text: "三班", value: "203" },
            { text: "四班", value: "204" },
            { text: "五班", value: "205" },
            { text: "六班", value: "206" },
            { text: "七班", value: "207" },
            { text: "八班", value: "208" },
          ],
        },
        {
          text: "三年级",
          value: "3",
          children: [
            { text: "一班", value: "301" },
            { text: "二班", value: "302" },
            { text: "三班", value: "303" },
            { text: "四班", value: "304" },
            { text: "五班", value: "305" },
            { text: "六班", value: "306" },
            { text: "七班", value: "307" },
            { text: "八班", value: "308" },
          ],
        },
      ],
      activeIcon: "circle",
      inactiveIcon: "passed",
      evaluationOptions: "入校行为",
      submitDisabled: false,
      photoList: [],
    };
  },
  methods: {
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join("/");
    },

    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
    },

    onSubmit(values) {
      console.log(values);
      this.submitDisabled = true;

      this.$dialog
        .alert({
          title: "提交成功",
          message: "",
          confirmButtonColor:'#ffa65f'
        })
        .then(() => {
          this.$router.go(0)
        })
        .catch(() => {
          
        });
    },
  },
};
</script>
