function setCookies(cname, cvalue, day, status = 'day') {
    var d = new Date()
  
    if (status === 'day') {
      d.setDate(d.getDate() + day)
    } else if (status === 'hours') {
      d.setHours(d.getHours() + day)
    } else if (status === 'min') {
      d.setMinutes(d.getMinutes() + day)
    }
  
    var expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }
  
  function getCookies(cname) {
    var name = cname + '='
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }
  
  export { setCookies, getCookies }
  