<template>
  <div id="app">

    <van-nav-bar
      :title='this.$store.state.title'
      :left-arrow='isHistory'
      @click-left='historyButton'
      safe-area-inset-top
      fixed
      placeholder 
    />
   
    <router-view/>

    <van-tabbar route active-color="#ffa65f" safe-area-inset-bottom placeholder >
      <van-tabbar-item icon="medal-o">荣誉广场</van-tabbar-item>
      <van-tabbar-item icon="point-gift-o">兑换中心</van-tabbar-item>
      <van-tabbar-item to="/" icon="flower-o">班级评价</van-tabbar-item>
      <van-tabbar-item to="/about" icon="contact">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>


<style>
html,body{max-width:768px; margin:0 auto; min-height: 100vh;}
html{box-shadow: 0 0 30px rgb(0 0 0 / 20%);}
*{box-sizing: border-box;}
#app{height: 100%;}
.wrapper{padding: 20px; min-height: calc(100vh - 50px - 46px);background-image: linear-gradient(132deg, #f9f7f3, #f9f9fb);}
.fiexd-max-width{max-width: 768px; left: 50%; transform: translateX(-50%);}

.van-cell{font-size: 16px;}
.van-tabbar--fixed,.van-nav-bar--fixed{max-width: 768px;left: 50%;transform: translateX(-50%);}
</style>


<script>

export default {
    data() {
      return {
        isHistory:true,
      }
      
    },
    methods: {
      historyButton(){
        this.$router.go(-1)
      }
    },
    mounted() {
      console.log(this.$store.state.title)
    }
  }
</script>
  
