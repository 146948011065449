import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store/index';
import {getCookies } from '../utils/cookies'
Vue.use(VueRouter)

const defaultTitle = '建设路小学'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => {
      console.log(location)
      document.title = '登录';
      store.commit('setTitle',document.title);
    });
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      title:'班级评价'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta:{
      title:'个人中心'
    },

  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta:{
      title:'登录'
    }
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




router.beforeEach((to, from, next) => {

  const token = getCookies('username')
  document.title = to.meta.title ? to.meta.title : defaultTitle;
  store.commit('setTitle',document.title);

  if(from.path == '/login' && token == 'admin'){
    return next();
  }else if(from.path == '/login' && token != 'admin'){
    return next('/login');
  }

  if (to.path !== '/login' && token != 'admin') {
    next('/login')
  }else{
    next()
  }
  

})





export default router
