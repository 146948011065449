import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index';
import '@vant/touch-emulator';

Vue.config.productionTip = false

import { NavBar,Tabbar, TabbarItem,Field,Form,Button,Toast,Cell, CellGroup,Cascader,Popup,RadioGroup, Radio,Uploader,Dialog } from 'vant';

Vue.use(NavBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Field);
Vue.use(Form);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Cascader);
Vue.use(Popup);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Uploader);
Vue.use(Dialog);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
